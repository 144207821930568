<template>
  <div>
    <el-button type="success" style="margin-bottom: 15px;" class="el-icon-document-add" @click="openAddSaleArea">
      添加销售区域
    </el-button>
    <el-table
        v-loading="viewListLoading"
        :data="tableData"
        style="width: 100%"
        :size="tableSize"
        :max-height="maxHeight"
        :row-class-name="tableRowClassName"
        :header-cell-style="{background:'#eef1f6',color:'#606266'}">
      <el-table-column
          prop="id"
          label="序号"
          min-width="100">
      </el-table-column>
      <el-table-column
          prop="belongCompanyName"
          label="销售公司名称"
          min-width="180">
      </el-table-column>
      <el-table-column
          prop="belongCompany"
          label="所属销售公司"
          min-width="180">
      </el-table-column>
      <el-table-column
          prop="belongArea"
          label="销售区域"
          min-width="180">
      </el-table-column>
      <el-table-column
          prop="state"
          label="状态"
          min-width="100">
      </el-table-column>
      <el-table-column
          fixed="right"
          label="操作"
          min-width ="120">
        <template slot-scope="scope">
          <el-tooltip content="编辑" placement="left-start" effect="light">
            <el-button
                @click.native.prevent="editRow(scope.$index, tableData)"
                icon="el-icon-edit"
                size="mini"
                type="primary">
            </el-button>
          </el-tooltip>
          <el-tooltip content="删除" placement="top-end" effect="light">
            <el-button
                @click.native.prevent="deleteRow(scope.$index, tableData)"
                icon="el-icon-delete"
                size="mini"
                type="danger">
            </el-button>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination" style="text-align: right;">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 15, 20]"
          :page-size="pagination.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pagination.total">
      </el-pagination>
    </div>
    <!-- 弹出框form -->
    <el-dialog title="销售区域"
               :visible.sync="dialogFormVisible"
               @close="closeDialog"
               :show-close="false"
               :close-on-press-escape="false"
               fullscreen
               center >
      <el-form ref="areaForm" :model="editForm" :rules="saleAreaFormRules" :size="formSize" :inline="true" label-position="right">
        <el-row>
          <el-col :span="12">
            <el-form-item label="销司" :size="formSize" :label-width="formLabelWidth" prop="belongCompanyId">
              <el-select v-model="editForm.belongCompanyId" placeholder="请选择" :size="formSize" @change="changeCompany">
                <el-option
                    v-for="item in saleList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                    :disabled="item.disabled">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="区域" :size="formSize" :label-width="formLabelWidth" prop="belongArea">
              <el-input v-model="editForm.belongArea" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="状态" :size="formSize" :label-width="formLabelWidth" prop="state">
              <el-select v-model="editForm.state" placeholder="请选择" :size="formSize">
                <el-option
                    v-for="item in stateList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备注" :size="formSize" :label-width="formLabelWidth">
              <el-input v-model="editForm.remark" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row style="margin-top: 15px;" v-show="hasCompanyId">
          <el-col :span="24">
            <div style="margin-left: 15px;">
              <h4>选择网点</h4>
              <el-input
                  v-model="search"
                  size="mini"
                  style="margin-bottom: 5px;width: 200px;"
                  placeholder="输入关键字搜索"/>
              <el-badge :value="selSiteList.length" class="item" style="float: right;">
                <el-button size="small" @click="openInnerDialog">查看已选网点</el-button>
              </el-badge>
              <el-table ref="siteTable"
                        :data="siteList"
                        @selection-change="handleSelectionChange"
                        :row-key="getRowKeys"
                        height="300"
                        size="mini"
                        border>
                <el-table-column type="selection" width="55" :reserve-selection="true"></el-table-column>
                <el-table-column property="websitNumber" label="网点编号" width="100"></el-table-column>
                <el-table-column property="name" label="网点名称"></el-table-column>
                <el-table-column property="address" label="网点地址"></el-table-column>
              </el-table>
              <div class="pagination" style="text-align: right;">
                <el-pagination
                    @size-change="dialogHandleSizeChange"
                    @current-change="dialogHandleCurrentChange"
                    :page-sizes="[50]"
                    :page-size="dialogPagination.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="dialogPagination.total">
                </el-pagination>
              </div>
            </div>
          </el-col>
        </el-row>
        <el-form-item>
        </el-form-item>
      </el-form>
      <el-dialog
          width="50%"
          title="已选网点信息"
          :visible.sync="innerVisible"
          append-to-body>
        <el-table
            :data="selSiteList"
            height="250"
            size="mini"
            border
            style="width: 100%">
          <el-table-column property="websitNumber" label="网点编号" width="100"></el-table-column>
          <el-table-column property="name" label="网点名称"></el-table-column>
          <el-table-column
              label="操作"
              width ="70">
            <template slot-scope="scope">
              <el-button
                  @click.native.prevent="deleteSelSiteRow(scope.$index, selSiteList)"
                  icon="el-icon-delete"
                  size="mini"
                  type="danger">
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-dialog>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogCancel">取 消</el-button>
        <el-button type="primary" @click="confirmFrom()" v-prevent-re-click>确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "MasterMobileSaleArea",
  methods: {
    sendRequest() {
      this.viewListLoading = true;
      this.getRequest('/master/mobile-sale-area/page?pageSize=' + this.pagination.pageSize
          + '&pageNum=' + this.pagination.pageNum).then((resp)=> {
        if(resp) {
          this.tableData = resp.data.records;
          this.pagination.total = resp.data.total;
        }
        this.viewListLoading = false;
      }, () => {this.viewListLoading = false;})
    },
    confirmFrom() {
      this.$refs.areaForm.validate((valid) => {
        if(valid) {
          //置空表单的网点列表重新获取
          this.editForm.mobileSaleAreaWebsitList = [];
          if(this.$refs.siteTable.selection && this.$refs.siteTable.selection.length > 0) {
            this.$refs.siteTable.selection.forEach(value => {
              this.editForm.mobileSaleAreaWebsitList.push({"websitNumber": value.websitNumber});
            })
          }
          if(this.inType) {
            this.postRequest('/master/mobile-sale-area/', this.editForm).then(resp => {
              if(resp) {
                //清空弹框数据并重新查询列表
                this.dialogCancel();
                this.sendRequest();
              }
            })
          } else {
            this.putRequest('/master/mobile-sale-area/', this.editForm).then(resp => {
              if(resp) {
                //清空弹框数据并重新查询列表
                this.dialogCancel();
                this.sendRequest();
              }
            })
          }
          console.log(JSON.stringify(this.editForm))
        }
      })
    },
    openAddSaleArea() {
      //点击添加事件
      this.inType = true;
      this.siteList = [];
      this.dialogFormVisible = true;
    },
    editRow(index, rows) {
      this.inType = false;
      //点击编辑按钮触发事件
      this.siteList = [];
      this.dialogFormVisible = true;
      let row = rows[index];
      //查询已选网点
      this.getRequest('/master/mobile-sale-area/info/' + row.id).then(resp => {
        if(resp) {
          this.editForm = resp.data;
          //初始化主体内容
          this.editForm.state = this.editForm.baseState
          //初始化第一页
          let originalSiteList = this.originalSiteList.filter(site => site.belongCompanyId == this.editForm.belongCompanyId)
          this.siteList = originalSiteList.slice((this.dialogPagination.pageNum-1)*this.dialogPagination.pageSize, this.dialogPagination.pageNum*this.dialogPagination.pageSize);
          this.dialogPagination.total = originalSiteList.length;
          //回显选中
          let selSiteList = this.editForm.mobileSaleAreaWebsitList;
          if(selSiteList && selSiteList.length > 0) {
            this.originalSiteList.forEach((value, index, array) => {
              selSiteList.find((s, i, o) => {
                if(s.websitNumber == value.websitNumber) {
                  this.$refs.siteTable.toggleRowSelection(value,true);
                }
              })
            })
          }
        }
      });
    },
    deleteRow(index, rows) {
      //删除当前销售区域
      let row = rows[index];
      let promptMessages = ['确认删除信息:', `序号: ${row.id}`, `销售区域: ${row.nickname}`]
      const newPromptMessages = []
      const h = this.$createElement
      for (const i in promptMessages) {
        newPromptMessages.push(h('p', null, promptMessages[i]))
      }
      //删除openid提示
      this.$confirm('提示', {
        message: h('div', null, newPromptMessages),
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: true,
        type: 'warning'
      }).then(() => {
        //发送删除请求
        this.deleteRequest('/master/mobile-sale-area/' + row.id).then(resp => {
          this.sendRequest();
        });
      }).catch(() => {
        console.log('取消删除')
      })
    },
    changeCompany() {
      this.$refs.siteTable.clearSelection();
      this.dialogHandleCurrentChange(1);
    },
    tableRowClassName({row, rowIndex}) {
      if (rowIndex % 2 === 1) {
        return 'row-bg-color';
      } else {
        return ''
      }
    },
    handleSizeChange(val) {
      this.pagination.pageSize = val;
      this.sendRequest();
    },
    handleCurrentChange(val) {
      this.pagination.pageNum = val;
      this.sendRequest();
    },
    dialogHandleSizeChange(val) {
      this.dialogPagination.pageSize = val;
    },
    dialogHandleCurrentChange(val) {
      this.dialogPagination.pageNum = val;
      if(this.search.length == 0) {
        let originalSiteList = this.originalSiteList.filter(site => site.belongCompanyId == this.editForm.belongCompanyId)
        this.siteList = originalSiteList.slice((this.dialogPagination.pageNum-1)*this.dialogPagination.pageSize, this.dialogPagination.pageNum*this.dialogPagination.pageSize);
        this.dialogPagination.total = originalSiteList.length;
      } else {
        let pageNum = this.dialogPagination.pageNum;
        let pageSize = this.dialogPagination.pageSize;
        let originalSiteList = this.originalSiteList.filter(site => site.belongCompanyId == this.editForm.belongCompanyId)
        let data = originalSiteList.filter(data => !this.search || data.name.includes(this.search)
            || data.address.includes(this.search)
            || data.websitNumber.toLowerCase().includes(this.search.toLowerCase()));
        this.dialogPagination.total = data.length;
        this.siteList = data.slice((pageNum-1)*pageSize, pageNum*pageSize);
      }
    },
    clearForm() {
      //清空提交表单内容
      this.$refs.areaForm.resetFields();
      this.editForm.id = '';
      this.editForm.belongCompanyId = -1;
      this.editForm.belongArea = '';
      this.editForm.remark = '';
      this.editForm.state = '1';
      this.editForm.mobileSaleAreaWebsitList = [];
      this.selSiteList = [];
      this.mobileSaleAreaWebsitList = [];
      this.search = '';
      this.$refs.siteTable.clearSelection();
      this.dialogHandleCurrentChange(1);
      this.dialogPagination.total = 0;
    },
    dialogCancel() {
      //点击取消按钮事件
      this.clearForm();
      this.dialogFormVisible = false;
    },
    closeDialog() {
      //关闭弹框时事件
      this.dialogCancel();
    },
    openInnerDialog() {
      this.innerVisible = true;
    },
    getRowKeys(row) {
      return row.id;
    },
    handleSelectionChange(val) {
      this.selSiteList = val;//勾选放在selSiteList数组中
    },
    deleteSelSiteRow(index, rows) {
      this.$refs.siteTable.toggleRowSelection(this.selSiteList[index], false);
    },
  },
  data() {
    return {
      viewListLoading: true,
      inType: true,
      tableSize: 'small',
      maxHeight: 500,
      tableData: [],
      pagination: {
        pageSize: 10,
        pageNum: 1,
        total: 0
      },
      dialogPagination: {
        pageSize: 50,
        pageNum: 1,
        total: 0
      },
      saleList: [
        {value: -1, label: '请选择', disabled: true},
        {value: 0, label: '总部'},
        {value: 1, label: '佛山'},
        {value: 2, label: '广州'}
      ],
      stateList: [
        {value: '1', label: '正常'},
        {value: '0', label: '禁用'}
      ],
      originalSiteList: [],
      siteList: [],
      selSiteList: [],
      editForm: {
        id: '',
        belongCompanyId: -1,
        belongArea: '',
        remark: '',
        state: '1',
        baseState: '',
        mobileSaleAreaWebsitList:[]
      },
      confirmBtnState: false,
      formLabelWidth: '75px',
      formSize: 'small',
      dialogFormVisible: false,
      innerVisible: false,
      search: '',
      saleAreaFormRules: {
        belongCompanyId: [
          { required: true, message: '请选择销司', trigger: 'change' }
        ],
        belongArea: [
          { required: true, message: '请输入销售区域', trigger: 'blur' },
          { min: 1, max: 16, message: '长度在 1 到 16 个中文字符', trigger: 'blur' }
        ],
        state: [
          { required: true, message: '请选择状态', trigger: 'change' }
        ]
      }
    }
  },
  watch: {
    'search': function (newVal, oldVal) {
      let pageNum = 1;
      let pageSize = this.dialogPagination.pageSize;
      let originalSiteList = this.originalSiteList.filter(site => site.belongCompanyId == this.editForm.belongCompanyId)
      let data = originalSiteList.filter(data => !this.search || data.name.includes(this.search)
          || data.address.includes(this.search)
          || data.websitNumber.toLowerCase().includes(this.search.toLowerCase()));
      this.dialogPagination.total = data.length;
      this.siteList = data.slice((pageNum-1)*pageSize, pageNum*pageSize);
    }
  },
  computed: {
    hasCompanyId() {
      return this.editForm.belongCompanyId !== undefined && this.editForm.belongCompanyId !== null
          && typeof(this.editForm.belongCompanyId) === 'number' && this.editForm.belongCompanyId >= 0
    }
  },
  mounted() {
    //vue加载完成后发送列表请求
    this.sendRequest();
    //vuex查询网点
    this.originalSiteList = this.$store.state.sites.filter(site => site.type.toLowerCase().includes('sale'));
  }
}
</script>

<style>
.el-table .row-bg-color {
  background: #eef1f6;
}
</style>