<template>
  <div>
    <el-button type="success" style="margin-bottom: 15px;" class="el-icon-document-add" @click="openAddRole">
      添加角色
    </el-button>
    <el-table
        v-loading="viewListLoading"
        :data="tableData"
        style="width: 100%"
        :size="tableSize"
        :max-height="maxHeight"
        :row-class-name="tableRowClassName"
        :header-cell-style="{background:'#eef1f6',color:'#606266'}">
      <el-table-column
          fixed
          prop="id"
          label="序号"
          min-width="50">
      </el-table-column>
      <el-table-column
          prop="name"
          label="名称"
          min-width="120">
      </el-table-column>
      <el-table-column
          prop="createTime"
          label="创建时间"
          min-width="180">
      </el-table-column>
      <el-table-column
          prop="modifyTime"
          label="修改时间"
          min-width="180">
      </el-table-column>
      <el-table-column
          fixed="right"
          label="操作"
          min-width ="120">
        <template slot-scope="scope">
          <el-tooltip content="编辑" placement="left-start" effect="light">
            <el-button
                @click.native.prevent="editRow(scope.$index, tableData)"
                icon="el-icon-edit"
                size="mini"
                type="primary">
            </el-button>
          </el-tooltip>
          <el-tooltip content="删除" placement="top-end" effect="light">
            <el-button
                @click.native.prevent="deleteRow(scope.$index, tableData)"
                icon="el-icon-delete"
                size="mini"
                type="danger">
            </el-button>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination" style="text-align: right;">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 15, 20]"
          :page-size="pagination.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pagination.total">
      </el-pagination>
    </div>

    <!-- 弹出框form -->
    <el-dialog title="角色" :visible.sync="dialogFormVisible" @close="closeDialog" fullscreen>
      <el-form :size="addFormSize" label-position="left">
        <el-form-item label="序号" :size="addFormSize" v-show="addForm.id" :label-width="formLabelWidth">
          <el-input disabled v-model="addForm.id"></el-input>
        </el-form-item>
        <el-form-item label="名称" :size="addFormSize" :label-width="formLabelWidth">
          <el-input v-model="addForm.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-table :data="gridData"
                  :max-height="maxHeight"
                  :loading="loading"
                  element-loading-text="加载中"
                  element-loading-spinner="el-icon-loading"
                  element-loading-background="rgba(0, 0, 0, 0.8)"
                  style="width: 100%"
                  ref="moduleTable">
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column property="moduleId" label="模块编码" width="100"></el-table-column>
          <el-table-column property="moduleName" label="模块名称" width="200"></el-table-column>
          <el-table-column property="moduleType" label="模块类型" width="100"></el-table-column>
          <el-table-column property="isQuery" label="查看">
            <template slot-scope="scope">
              <el-switch
                  v-model="gridData[scope.$index].isQuery"
                  @change="changeTableState('query', scope.$index, gridData)"
                  active-color="#13ce66"
                  inactive-color="#ff4949">
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column property="isEdit" label="编辑">
            <template slot-scope="scope">
              <el-switch
                  v-model="gridData[scope.$index].isEdit"
                  :disabled="gridData[scope.$index].baseModuleType === 0"
                  @change="changeTableState('edit', scope.$index, gridData)"
                  active-color="#13ce66"
                  inactive-color="#ff4949">
              </el-switch>
            </template>
          </el-table-column>
        </el-table>
        <el-form-item>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogCancel">取 消</el-button>
        <el-button type="primary" @click="addOrEditForm(dialogFormButton)">确 定</el-button>
      </div>
    </el-dialog>
  </div>

</template>

<script>
export default {
  name: "MasterMobileRole",
  methods: {
    sendRequest(pageNum) {
      this.viewListLoading = true;
      //发送查询角色的请求方法
      let reqPageNum = pageNum ? pageNum : this.pagination.pageNum;
      this.getRequest('/master/mobile-role/?pageSize=' + this.pagination.pageSize
          + '&pageNum=' + reqPageNum).then((resp)=> {
        if(resp) {
          this.tableData = resp.data.records;
          this.pagination.total = resp.data.total;
        }
        this.viewListLoading = false;
      }, () => {this.viewListLoading = false;})
    },
    openAddRole() {
      //点击添加按钮时事件
      this.dialogFormVisible = true;
      this.getModuleList();
    },
    addOrEditForm(type) {
      //处理添加或编辑确定的方法
      if(type) {
        this.addForm.moduleList = this.$refs.moduleTable.selection;
        this.postRequest('/master/mobile-role/', this.addForm).then(resp => {
          if(resp) {
            this.closeDialog();
            this.sendRequest(1);
          }
        })
      } else {
        this.addForm.moduleList = this.$refs.moduleTable.selection;
        this.putRequest('/master/mobile-role/', this.addForm).then(resp => {
          this.sendRequest()
        })
      }
    },
    editRow(index, rows) {
      //点击编辑按钮事件
      let row = rows[index];
      this.addForm.id = row.id;
      this.addForm.name = row.name;
      this.dialogFormVisible = true;
      this.dialogFormButton = false;
      this.getModuleList(row);
    },
    deleteRow(index, rows) {
      //点击删除按钮事件
      let row = rows[index];
      let promptMessages = ['确认删除信息:', `序号: ${row.id}`, `名称: ${row.name}`]
      const newPromptMessages = []
      const h = this.$createElement
      for (const i in promptMessages) {
        newPromptMessages.push(h('p', null, promptMessages[i]))
      }
      //删除角色
      this.$confirm('提示', {
        message: h('div', null, newPromptMessages),
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: true,
        type: 'warning'
      }).then(() => {
        //发送删除请求
        this.deleteRequest('/master/mobile-role/' + row.id).then(resp => {
          this.sendRequest();
        });

      }).catch(() => {
        console.log('取消删除')
      })
    },
    changeTableState(type, index, rows) {
      //switch改变状态时触发事件
      let row = rows[index];
      // console.log(row);
    },
    handleSizeChange(val) {
      //改变每页显示多少条记录触发事件
      this.pagination.pageSize = val;
      this.sendRequest();
    },
    handleCurrentChange(val) {
      //改变当前页码时触发事件
      this.pagination.pageNum = val;
      this.sendRequest();
    },
    tableRowClassName({row, rowIndex}) {
      //隔行背景色变色处理
      if (rowIndex % 2 === 1) {
        return 'row-bg-color';
      } else {
        return ''
      }
    },
    clearForm() {
      //清空提交表单内容
      this.addForm.id = '';
      this.addForm.name = '';
      this.addForm.moduleList = [];
      this.$refs.moduleTable.clearSelection();
      this.gridData = [];
    },
    dialogCancel() {
      //点击取消按钮事件
      this.dialogFormVisible = false;
      this.dialogFormButton = true;
      this.clearForm();
    },
    closeDialog() {
      //关闭弹框时事件
      this.dialogCancel();
    },
    getModuleList(row) {
      this.loading = true;
      this.getRequest('/common-query/mobile/module').then(resp => {
        if(resp) {
          resp.forEach((value, index, array) => {
            value.mmId = value.id;
            value.isQuery = true;
            value.isEdit = value.baseModuleType != 0 ? true : false;
          })
          this.gridData = resp;
          if(row) {
            this.getRequest('/master/mobile-role/info/' + row.id).then(resp => {
              if(resp) {
                let moduleList = resp.data.moduleList
                if(moduleList && moduleList.length > 0) {
                  if(moduleList && this.gridData.length > 0) {
                    this.gridData.forEach((value, index, array) => {
                      moduleList.find((m, i, o) => {
                        if(m.mmId == value.mmId) {
                          value.isQuery = m.isQuery;
                          value.isEdit = m.isEdit;
                          this.$refs.moduleTable.toggleRowSelection(value,true);
                        }
                      })
                    })
                  }
                }
              }
            })
          }
          this.loading = false;
        }
      })
    }
  },
  data() {
    return {
      viewListLoading: true,
      loading: true,
      tableSize: 'medium',
      maxHeight: 500,
      tableData: [],
      pagination: {
        pageSize: 10,
        pageNum: 1,
        total: 0
      },
      dialogFormVisible: false,
      addForm: {
        id: '',
        name: '',
        moduleList: []
      },
      gridData: [],
      formLabelWidth: '120px',
      addFormSize: 'small',
      dialogFormButton: true
    }
  },
  computed: {
    isDisabled() {
      //判断点击按钮触发的是添加还是编辑时事件
      let id = this.addForm.id;
      if(id !== undefined && id !== null && id !== '')
        return true;
      return false;
    }
  },
  mounted() {
    //vue组件加载完成后触发自动查询
    this.sendRequest();
  }
}
</script>

<style>
.el-table .row-bg-color {
  background: #eef1f6;
}
</style>