<template>
  <div>
    <el-table
        :data="tableData"
        style="width: 100%"
        :size="tableSize"
        :max-height="maxHeight"
        :row-class-name="tableRowClassName"
        :header-cell-style="{background:'#eef1f6',color:'#606266'}">
      <el-table-column
          fixed
          prop="websitNumber"
          label="网点编号"
          align="center"
          min-width="100">
      </el-table-column>
<!--      <el-table-column-->
<!--          prop="belongCompanyId"-->
<!--          label="公司id"-->
<!--          min-width="80">-->
<!--      </el-table-column>-->
      <el-table-column
          prop="belongCompany"
          label="所属销售公司"
          min-width="120">
      </el-table-column>
      <el-table-column
          prop="name"
          label="网点名称"
          align="center"
          min-width="180">
      </el-table-column>
      <el-table-column
          prop="oldNumber"
          label="旧网点编号"
          align="center"
          min-width="120">
      </el-table-column>
      <el-table-column
          prop="type"
          label="网点类别"
          min-width="100">
      </el-table-column>
      <el-table-column
          prop="address"
          label="详细地址"
          min-width="250">
      </el-table-column>
    </el-table>
    <div class="pagination" style="text-align: right;">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 15, 20]"
          :page-size="pagination.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pagination.total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: "MasterMobileWebsit",
  methods: {
    sendRequest(pageNum) {
      let reqPageNum = pageNum ? pageNum : this.pagination.pageNum;
      this.getRequest('/master/mobile-websit/page/?pageSize=' + this.pagination.pageSize
          + '&pageNum=' + reqPageNum).then((resp) => {
        if (resp) {
          this.tableData = resp.data.records;
          this.pagination.total = resp.data.total;
        }
      })
    },
    handleSizeChange(val) {
      this.pagination.pageSize = val;
      this.sendRequest();
    },
    handleCurrentChange(val) {
      this.pagination.pageNum = val;
      this.sendRequest();
    },
    tableRowClassName({row, rowIndex}) {
      if (rowIndex % 2 === 1) {
        return 'row-bg-color';
      } else {
        return ''
      }
    }
  },
  data() {
    return {
      tableSize: 'medium',
      maxHeight: 500,
      tableData: [],
      pagination: {
        pageSize: 10,
        pageNum: 1,
        total: 0
      }
    }
  },
  mounted() {
    this.sendRequest();
  }
}
</script>

<style scoped>

</style>