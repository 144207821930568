<template>
  <div>
    <el-button type="success" style="margin-bottom: 15px;" class="el-icon-document-add" @click="dialogFormVisible = true">
      添加模块
    </el-button>
    <el-table
        v-loading="viewListLoading"
        :data="tableData"
        style="width: 100%"
        :size="tableSize"
        :max-height="maxHeight"
        :row-class-name="tableRowClassName"
        :header-cell-style="{background:'#eef1f6',color:'#606266'}">
      <el-table-column
          fixed
          prop="moduleId"
          label="模块id"
          min-width="80">
      </el-table-column>
      <el-table-column
          prop="moduleName"
          label="模块名称"
          min-width="120">
      </el-table-column>
      <el-table-column
          prop="moduleType"
          label="模块类型"
          align="center"
          min-width="80">
      </el-table-column>
      <el-table-column
          prop="moduleState"
          label="模块状态"
          align="center"
          min-width="120">
      </el-table-column>
      <el-table-column
          prop="createTime"
          label="创建时间"
          min-width="180">
      </el-table-column>
      <el-table-column
          prop="modifyTime"
          label="修改时间"
          min-width="180">
      </el-table-column>
      <el-table-column
          fixed="right"
          label="操作"
          min-width ="120">
        <template slot-scope="scope">
          <el-tooltip content="编辑" placement="left-start" effect="light">
            <el-button
                @click.native.prevent="editRow(scope.$index, tableData)"
                icon="el-icon-edit"
                size="mini"
                type="primary">
            </el-button>
          </el-tooltip>
          <el-tooltip :content="scope.row.moduleState == '启用' ? '禁用' : '启用'" placement="top-end" effect="light">
            <el-button
                @click.native.prevent="changeRow(scope.$index, tableData)"
                icon="el-icon-set-up"
                size="mini"
                :type="scope.row.moduleState == '启用' ? 'danger' : 'success'">
            </el-button>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination" style="text-align: right;">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 15, 20]"
          :page-size="pagination.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pagination.total">
      </el-pagination>
    </div>

    <!-- 弹出框form -->
    <el-dialog title="模块" :visible.sync="dialogFormVisible" @close="closeDialog">
     <el-form :size="addFormSize" label-position="left">
       <el-form-item label="序号" :size="addFormSize" v-show="addForm.id" :label-width="formLabelWidth">
         <el-input disabled v-model="addForm.id"></el-input>
       </el-form-item>
       <el-form-item label="模块编码" :size="addFormSize" :label-width="formLabelWidth">
         <el-input :disabled = "isDisabled" v-model="addForm.moduleId" autocomplete="off"></el-input>
       </el-form-item>
       <el-form-item label="模块名称" :size="addFormSize" :label-width="formLabelWidth">
         <el-input v-model="addForm.moduleName" autocomplete="off"></el-input>
       </el-form-item>
       <el-form-item label="模块类型" :size="addFormSize" :label-width="formLabelWidth">
         <el-select :disabled = "isDisabled"  v-model="addForm.moduleType" placeholder="请选择模块类型">
           <el-option label="报表" value="REPORT"></el-option>
           <el-option label="业务" value="BUSINESS"></el-option>
         </el-select>
       </el-form-item>
       <el-form-item label="模块状态" :size="addFormSize" :label-width="formLabelWidth">
         <el-select v-model="addForm.moduleState" placeholder="请选择模块类型">
           <el-option label="启用" value="ENABLED"></el-option>
           <el-option label="禁用" value="DISABLED"></el-option>
         </el-select>
       </el-form-item>
       <el-form-item>
       </el-form-item>
     </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogCancel">取 消</el-button>
        <el-button type="primary" @click="addOrEditForm(dialogFormButton)">确 定</el-button>
      </div>
    </el-dialog>
  </div>

</template>

<script>
export default {
  name: "MasterMobileModule",
  methods: {
    sendRequest(pageNum) {
      this.viewListLoading = true;
      let reqPageNum = pageNum ? pageNum : this.pagination.pageNum;
      this.getRequest('/master/mobile-module/?pageSize=' + this.pagination.pageSize
          + '&pageNum=' + reqPageNum).then((resp)=> {
        if(resp) {
          this.tableData = resp.data.records;
          this.pagination.total = resp.data.total;
        }
        this.viewListLoading = false;
      }, () => {this.viewListLoading = false;})
    },
    addOrEditForm(type) {
      if(type) {
        this.postRequest('/master/mobile-module/', this.addForm).then(resp => {
          if(resp) {
              this.closeDialog();
              this.sendRequest(1);
          }
        })
      } else {
        this.putRequest('/master/mobile-module/', this.addForm).then(resp => {
          this.sendRequest();
        })
      }
    },
    editRow(index, rows) {
      let row = rows[index];
      this.addForm.id = row.id;
      this.addForm.moduleId = row.moduleId;
      this.addForm.moduleName = row.moduleName;
      this.addForm.moduleType = row.moduleType == '报表' ? 'REPORT' : 'BUSINESS';
      this.addForm.moduleState = row.moduleState == '启用' ? 'ENABLED' : 'DISABLED';
      this.dialogFormVisible = true;
      this.dialogFormButton = false;
    },
    changeRow(index, rows) {
      let row = rows[index];
      //变更模块状态
      this.$confirm('提示', {
        message: "确认变更状态？",
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: true,
        type: 'warning'
      }).then(() => {
        //发送模块状态请求
        this.putRequest('/master/mobile-module/state/' + row.id).then(resp => {
          if(resp) {
            this.sendRequest();
          }
        });
      }).catch(() => {
        console.log('取消变更')
      })
    },
    handleSizeChange(val) {
      this.pagination.pageSize = val;
      this.sendRequest();
    },
    handleCurrentChange(val) {
      this.pagination.pageNum = val;
      this.sendRequest();
    },
    tableRowClassName({row, rowIndex}) {
      if (rowIndex % 2 === 1) {
        return 'row-bg-color';
      } else {
        return ''
      }
    },
    clearForm() {
      this.addForm.id = '';
      this.addForm.moduleId = '';
      this.addForm.moduleName = '';
      this.addForm.moduleType = 'REPORT';
      this.addForm.moduleState = 'ENABLED';
    },
    dialogCancel() {
      this.dialogFormVisible = false;
      this.dialogFormButton = true;
      this.clearForm();
    },
    closeDialog() {
      this.dialogCancel();
    }
  },
  data() {
    return {
      viewListLoading: true,
      tableSize: 'medium',
      maxHeight: 500,
      tableData: [],
      pagination: {
        pageSize: 10,
        pageNum: 1,
        total: 0
      },
      dialogFormVisible: false,
      addForm: {
        id: '',
        moduleId: '',
        moduleName: '',
        moduleType: 'REPORT',
        moduleState: 'ENABLED'
      },
      formLabelWidth: '120px',
      addFormSize: 'small',
      dialogFormButton: true
    }
  },
  computed: {
    isDisabled() {
      let id = this.addForm.id;
      if(id !== undefined && id !== null && id !== '')
        return true;
      return false;
    }
  },
  mounted() {
    this.sendRequest();
  }
}
</script>

<style>
.el-table .row-bg-color {
  background: #eef1f6;
}
</style>